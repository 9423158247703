/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


// jQuery(document).ready(
//   jQuery(window).scroll(function() {
//     if (jQuery(this).scrollTop() > 145){  
//       jQuery('header').addClass("sticky");
//       jQuery('.navbar').css("width", "100% !important");
//     }
//     else {
//       jQuery('header').removeClass("sticky");
//     }
//   })
// );

jQuery('a').click(function(){
    jQuery('html, body').animate({
        scrollTop: jQuery( jQuery(this).attr('href') ).offset().top
    }, 500);
    return false;
});

jQuery(document).ready(
  jQuery(window).scroll(function() {
    if (jQuery(this).scrollTop() > 140){  
      jQuery('.rec-stick').addClass("sticky-rec");
      jQuery('.navbar').css("width", "100% !important");
    }
    
    else {
      jQuery('header').removeClass("sticky-rec");
    }
  })
);

jQuery(function() {
      // Clickable Dropdown
      jQuery('.click-nav > ul').toggleClass('no-js js');
      jQuery('.click-nav .js ul').hide();
      jQuery('.click-nav .js').click(function(e) {
        jQuery('.click-nav .js ul').slideToggle(200);
        jQuery('.clicker').toggleClass('active');
        e.stopPropagation();
      });
      jQuery(document).click(function() {
        if (jQuery('.click-nav .js ul').is(':visible')) {
          jQuery('.click-nav .js ul', this).slideUp();
          jQuery('.clicker').removeClass('active');
        }
      });
    });

( function( $ ) {
  var collapseBtn = $( '#collapse-btn' );
  var expandBtn = $( '#expand-btn' );
  var accordion = $( '.recommendations-second-block input' );

  collapseBtn.hide();
  expandBtn.click( function() {
    accordion.prop( 'checked', true );
    expandBtn.hide();
    collapseBtn.show();
  } );

  collapseBtn.click( function() {
    accordion.prop( 'checked', false );
    collapseBtn.hide();
    expandBtn.show();
  } );

  accordion.change( function() {
    if ( accordion.filter( ':checked' ).length === accordion.length ) { // if all are open / checked
      expandBtn.hide();
      collapseBtn.show();
    } else { // not all of them are open / checked
      expandBtn.show();
      collapseBtn.hide();
    }
  } );
} )( jQuery );
